/*===================
    Section Title 
====================*/

.section-title {
    margin-top: -20px;
    h2 {
        &.title{
            font-size: 60px;
            margin-bottom: 8px;
            @media #{$lg-layout} {
                font-size: 50px;
            }
            @media #{$sm-layout} {
                font-size: 30px;
                margin-bottom: 10px;
            }
        }
        @media #{$lg-layout} {
            font-size: 50px;
        }

        @media #{$sm-layout} {
            font-size: 30px;
            margin-bottom: 10px;
        }

    }

    p {
        font-size: 18px;
        line-height: 30px;
        color: rgba(29, 29, 36, 0.75);
        padding: 0 21%;
        @media #{$sm-layout} {
            padding: 0;
            font-size: 16px!important;
            line-height: 28px!important;
        }
        @media #{$md-layout} {
            padding: 0 4%;
        }
        @media #{$lg-layout} {
            padding: 0 16%;
        }
        a {
            color: rgba(29, 29, 36, 1);
            font-weight: 500;
            &:hover {
                color: $theme-color;
            }
        }
        br {
            @media #{$sm-layout} {
                display: none;    
            }
        }
    }

    h3 {
        font-size: 36px;
        line-height: 26px;
        @media #{$sm-layout} {
            font-size: 26px;
            margin-bottom: 8px;
        }
    }

    &.text-start {
        p{
            padding: 0;
        }
    }

    &.text-end {
        p{
            padding: 0;
        }
    }

    .service-btn {
        margin-top: 36px;
        @media #{$sm-layout} {
            margin-top: 12px;
        }
    }

    &.section-title--2 {
        p {
            font-weight: 300;
            color: #717173;
        }

        &.text-white {
            .title {
                color: #ffffff;
            }
            p {
                color: #ffffff;
            }
        }
    }

    &.service-style--3 {
        p {
            padding: 0 21%;
            font-weight: 300;
            color: #717173;
            @media #{$md-layout} {
                padding: 0 7%;
            }
            @media #{$sm-layout} {
                padding: 0;
            }
        }
        &.text-start {
            p {
                padding: 0 3% 0 0;
            }
        }
    }


}

.section-single-title {
    h3 {
        font-size: 36px;
        line-height: 26px;
        @media #{$sm-layout} {
            font-size: 26px;
            margin-bottom: 8px;
        }
    }
}

.section-title-default {
    h2 {
        &.title {
            line-height: 1.34;
            font-size: 48px;
            @extend %fontWeight400;
        }
    }
}

