/*----------------------
    Social Styles  
-----------------------*/


/*----------------------
    Social Share 
------------------------*/
ul {
    &.social-transparent {
        margin: -8px;
        li {
            margin: 8px;
            a {
                width: auto;
                display: inline-block;
                height: auto;
                border: 0 none;
                line-height: initial;
                color: #000;
                border-radius: 100%;
                text-align: center;
                font-size: 14px;
                transition: 0.3s;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}

