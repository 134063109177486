/*==========================
    Service Area 
===========================*/
/* Bg Color Variation  */
.single-service {
    .icon {
        svg {
            width: 54px;
            height: 54px;
        }
    }
    h3 {
        &.title {
            @media #{$lg-layout} {
                font-size: 22px;
            }
        }
    }
    // Service Style One
    &.service__style--1 {
        padding: 40px 0;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        @media #{$sm-layout} {
            padding: 15px 0;
        }
        @media #{$md-layout} {
            padding: 20px 0;
        }
        .icon {
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;
            svg {
                stroke-width: 1 !important;
            }
            img {
                margin-bottom: 34px;
            }
        }
        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 400;
                    @extend %transition;

                    @media #{$sm-layout} {
                        font-size: 20px;
                    }
                    a {
                        color: inherit;
                    }
                }
            }
            p {
                @extend %transition;
                color: rgba(29, 29, 36, 0.75);
            }
        }

        &.text-white {
            z-index: 2;
            .content {
                h3 {
                    &.title {
                        color: #c6c9d8;
                    }
                }
                p {
                    color: #c6c9d8;
                }
            }
        }
    }

    &.service__style--2 {
        @extend %posrelative;
        a {
            display: block;
            @extend %transition;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
        }

        @media #{$sm-layout} {
            padding: 30px 14px;
        }

        @media #{$large-mobile} {
            padding: 30px 18px;
            margin-top: 30px;
        }
        .icon {
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;

            @media #{$large-mobile} {
                color: #ffffff;
            }

            svg {
                stroke-width: 1 !important;
            }
        }
        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 500;
                    @extend %transition;

                    @media #{$sm-layout} {
                        font-size: 18px;
                        margin-bottom: 14px;
                    }

                    @media #{$large-mobile} {
                        color: #ffffff;
                    }

                    a {
                        color: inherit;

                        @media #{$large-mobile} {
                            color: #ffffff;
                        }
                    }
                }
            }

            p {
                @extend %transition;
                color: rgba(29, 29, 36, 0.75);

                @media #{$large-mobile} {
                    color: #ffffff;
                }
            }
        }
        &:hover {
            a {
                transform: translateY(-5px);
            }
            .icon {
                color: #ffffff;
            }
            .content {
                h3 {
                    &.title {
                        @extend %textwhite;
                    }
                }

                p {
                    @extend %textwhite;
                }
            }
        }
        &.service-left-align {
            display: flex;
            padding: 50px 24px;
            .icon {
                padding-right: 20px;
            }
        }
        a {
            padding: 60px 45px;
            z-index: 2;
            border-radius: 10px;

            @media #{$sm-layout} {
                padding: 60px 14px;
            }

            @media #{$lg-layout} {
                padding: 50px 11px;
            }

            @media #{$md-layout} {
                padding: 60px 25px;
            }

            &::before {
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                background-image: linear-gradient(to right, #f61b10, #ef0963);
                width: 100%;
                height: 100%;
                z-index: -1;
                opacity: 0;
                visibility: hidden;
                @extend %transition;
                border-radius: 10px;

                @media #{$large-mobile} {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        &.bg-color-gray,
        &.bg-color-white {
            margin-top: 0;
            @media #{$lg-layout} {
                padding: 0;
            }
            @media #{$md-layout} {
                padding: 0;
            }
            @media #{$sm-layout} {
                padding: 0;
            }
            a {
                display: flex;
                height: 100%;
                background: #f6f6f6;
                position: relative;

                @media #{$lg-layout} {
                    padding: 33px 22px;
                }
                @media #{$md-layout} {
                    padding: 35px 14px;
                }
                @media #{$sm-layout} {
                    padding: 35px 14px;
                }
            }
        }

        // Bg White Color 
        &.bg-color-white {
            a {
                background: hsla(0,0%,100%,.3);
            }
            .icon {
                color: #ffffff;
            }
            .content {
                h3 {
                    &.title {
                        color: #ffffff;
                    }
                }
                p {
                    color: #ffffff;
                }
            }
        }

        &:hover {
            a {
                box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
                &::before {
                    opacity: 1;
                    visibility: visible;
                }
            }

            &.bg-color-gray,
            &.bg-color-white {
                a {
                    background: transparent;
                }
            }
        }
    }

    &.service__style--3 {
        padding: 40px 0;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        margin-top: 50px;

        @media #{$laptop-device} {
            margin-top: 20px;
        }
        @media #{$sm-layout} {
            padding: 15px 0;
            margin-top: 0;
        }
        @media #{$md-layout} {
            padding: 20px 0;
        }
        .icon {
            font-size: 54px;
            font-weight: 400;
            display: inline-flex;
            color: $theme-color;
            img {
                margin-bottom: 34px;
                @media #{$large-mobile} {
                    margin-bottom: 13px;
                    height: 45px;
                }
            }
        }

        .content {
            h3 {
                &.title {
                    margin-bottom: 20px;
                    font-weight: 400;
                    font-size: 24px;

                    @media #{$sm-layout} {
                        font-size: 20px;
                    }

                    @media #{$large-mobile} {
                        color: #ffffff;
                    }
                }
            }

            p {
                opacity: .75;
                font-weight: 300;
                color: #c6c9d8;
            }
        }

        &.text-white {
            z-index: 2;

            .content {
                h3 {
                    &.title {
                        color: #c6c9d8;
                    }
                }

                p {
                    color: #c6c9d8;

                }
            }
        }
    }

    // Service Style Four 
    &.service__style--4 {
        padding: 30px 38px;
        z-index: 2;
        border-radius: 10px; 
        position: relative;
        @extend %transition;
        cursor: pointer;
        @media #{$laptop-device} {
            padding: 20px 20px;
        }
        @media #{$lg-layout} {
            padding: 20px 20px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
        }
        @media #{$sm-layout} {
            padding: 20px 20px;
        }
        @media #{$large-mobile} {
            margin-top: 30px;
        }
        &.large-size {
            padding: 60px 45px;
            @media #{$lg-layout} {
                padding: 25px 20px;
            }
            @media #{$md-layout} {
                padding: 30px 25px;
            }
            @media #{$sm-layout} {
                padding: 25px 20px;
            }
        }

        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background-image: -webkit-gradient(linear,left top,right top,from(#f61b10),to(#ef0963));
            background-image: -webkit-linear-gradient(left,#f61b10,#ef0963);
            background-image: linear-gradient(90deg,#f61b10,#ef0963);
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            border-radius: 10px;
            @extend %transition;
            @media #{$large-mobile} {
                opacity: 1;
                visibility: visible;
            }
        }
        a{
            display: block;
        }
        .icon {
            display: inline-flex;
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;
            @media #{$sm-layout} {
                margin-bottom: 18px;
            }
            @media #{$large-mobile} {
                color: #fff;
            }
            svg {
                stroke-width: 1 !important;
            }
        }
        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 500;
                    @extend %transition;
                    @media #{$laptop-device} {
                        margin-bottom: 12px;
                        font-size: 20px;
                    }
                    @media #{$large-mobile} {
                        color: #fff;
                    }
                    @media #{$sm-layout} {
                        margin-bottom: 10px;
                        font-size: 18px;
                    }
                }
            }
            p {
                color: rgba(29,29,36,.75);
                @extend %transition;
                @media #{$large-mobile} {
                    color: #fff;
                }
            }
        }
        &:hover {
            box-shadow: 0 10px 25px 10px rgba(0,0,0,.1);
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
            &::before {
                opacity: 1;
                visibility: visible;
            }
            .icon {
                color: #fff;
            }
            .content {
                h3 {
                    &.title {
                        color: #fff;
                    }
                }
                p {
                    color: #fff;
                }
            }
        }
    }
}


/* ---------------------------
    Standard Service  
-------------------------------*/
.standard-service {
    .thumbnai {
        img {
            border-radius: 5px;
        }
    }
    .content{
        h3 {
            font-size: 19px;
            margin-top: 30px;
            margin-bottom: 10px;
            font-weight: 600;
            a {
                @extend %transition;
                color: $heading-color;
                &:hover {
                    color: $theme-color;
                }
            }
        }
        p {
            font-size: 15px;
            line-height: 24px;
        }
        a {

        }
    }
}




/*---------------------------
    Paralax Service Style  
-----------------------------*/

.rn-paralax-service {
    .service__style--2 {
        z-index: 2;
        padding: 40px 40px;
        background: rgba(255, 255, 255, 0.3);
        margin-top: 40px;
        overflow: hidden;
        border: 2px solid transparent;

        @media #{$sm-layout} {
            padding: 30px 20px;
            background: transparent;
            margin-top: 30px;
        }

        .icon {
            color: #ffffff;
        }

        .content {
            h3 {
                &.title {
                    font-size: 19px;
                    color: #ffffff;
                }
            }

            p {
                color: rgba(255, 255, 255, 0.75);
            }
        }

        &:hover {
            border: 2px solid $theme-color;

            &::before {
                border-radius: 0;
            }
        }
    }
}

.service-one-wrapper {
    @media #{$md-layout} {
        margin: 0 -40px;
    }
    @media #{$sm-layout} {
        margin: 0 -40px;
    }
    @media #{$large-mobile} {
        margin: 0 -15px;
    }
}







