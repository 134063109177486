/*===================
Button Area 
====================*/
a {
    &.btn-transparent{
        color: #1f1f25;
        @extend %transition;
        font-size: 16px;
        font-weight: 500;
        position: relative;
        display: inline-block;
        padding-bottom: 3px;
        &:hover{
            color: $theme-color;
        }
        &::after{
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            width: 40px;
            height: 2px;
            background: $theme-color;
            opacity: 0.75;
            @extend %transition;
        }
        &:hover{
            &::after{
                width: 100%;
            }
        }
    }
}


a.rn-btn ,
button.rn-btn,
.rn-btn {
    padding: 0 23px;
    height: 40px;
    display: inline-block;
    line-height: 34px;
    border: 2px solid $theme-color;
    border-radius: 4px;
    font-size: 14px;
    position: relative;
    z-index: 2;
    color: $theme-color;
    letter-spacing: 0.2px;
    @extend %transition;
    text-transform: uppercase;
    &:hover {
        border: 2px solid $theme-color;
        transform: translateY(-3px);
        color: #ffffff;
        background: $theme-color;
    }
    &.btn-white {
        border: 2px solid #ffffff;
        color: #ffffff;
        &:hover {
            border-color: $theme-color;
        }
    }
}

button,
a {
    &.rn-button-style--2 {
        color: #c6c9d8;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 2px;
        border: 2px solid #b1b4c1;
        padding: 15px 40px;
        border-radius: 6px;
        display: inline-block;
        font-weight: 500;
        transition: 0.3s;

        @media #{$sm-layout} {
            padding: 9px 25px;
        }

        &.btn_solid {
            border: 2px solid $theme-color;
            background: $theme-color;
            color: #ffffff;
        }

        &.btn-size-md {
            padding: 9px 15px;
            letter-spacing: 1px;
            font-size: 15px;
        }
        &.btn-size-sm {
            padding: 11px 15px;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 13px;
        }

        &.btn_border {
            border: 2px solid $theme-color;
            color: $theme-color;
            &:hover {
                background: $theme-color;
                color: #fff;
            }
            &.btn_border--dark {
                border: 2px solid #1f1f25;
                color: #1f1f25;
                &:hover {
                    background: #1f1f25;
                    color: #fff;
                    box-shadow: 0 10px 15px 0 rgba(31, 31, 37, 0.1);
                }
            }
        }

        &.border-opacity-half{
            border-color: rgba(31, 31, 37, 0.1);
        }

        &.rn-btn-small {
            padding: 10px 20px;
        }

        &.btn-primary-color {
            &:hover {
                border: 2px solid $theme-color;
                background: $theme-color;
                color: #ffffff;
            }
        }

       
        
        &.btn-solid {
            background: $theme-color;
            color: #ffffff;
            border-color: $theme-color;
        }

        &:hover {
            background: transparent;
            color: $theme-color;
            box-shadow: 0 10px 15px 0 rgba(249, 0, 77,0.1);
            transform: translateY(-5px);
        }
    }
}


.button-group {
    margin: -10px;
    a {
        margin: 10px;
    }
}








/*-----------------------
    Video Button  
-------------------------*/

.yu2fvl {
    z-index: 9999;
    top: 0;
}
.yu2fvl-iframe {
    display: block;
    height: 100%;
    width: 100%;
    border: 0;
}
.yu2fvl-overlay {
    z-index: 9998;
    background: #000;
    opacity: 0.8;
}

.yu2fvl-close {
    position: absolute;
    top: 1px;
    left: 100%;
    display: inline-block;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    background: #f10 !important;
    background-color: #f10 !important;
    color: #ffffff;
    border: 0;
    background: none;
    cursor: pointer;
}

@media #{$md-layout} {
    .yu2fvl-close {
        left: auto;
        right: 0;
        top: auto;
        bottom: 100%;
    }
}
@media #{$sm-layout} {
    .yu2fvl-close {
        left: auto;
        right: 0;
        top: auto;
        bottom: 100%;
    }
    .video {
        h2 {
            color: #ffffff;
            font-size: 32px;
        }

        h4 {
            font-size: 22px;
            line-height: 32px;
        }
    }
}

